import { useState, useEffect } from "react";
import "../../assets/scss/libs/editors/quill.scss";

import AWS from "aws-sdk";
import { Card, Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/default";

import Block from "../../components/Block/Block";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
//import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
//import dayjs from "dayjs";
import { successMessage, failedMessage } from "../utilities/alert-config";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
//import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import //Select,
//Quill,
//FileUpload,
//Tags,
//ImageUpload,
//DatePicker,
// Tinymce,
// {Icon} from
"../../components";
import {
  rootURL,
  awsID,
  awsBucketName,
  awsSECRET,
} from "../utilities/axios-config";
// import Checkbox from "@mui/material/Checkbox";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const Input = styled("input")({
//   display: "none",
// });

function ViewActor() {
  const { id } = useParams();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setname] = useState("");
  const [alsoknownas, setalsoknownas] = useState("");
  const [place_of_birth, setplace_of_birth] = useState("");
  const [photo, setphoto] = useState("/images/avatar/avatar-placeholder.jpg");
  const [person_info, setperson_info] = useState("");
  const [imdb_id, setimdb_id] = useState("");
  const [tmdb_id, settmdb_id] = useState("");
  const [catalog_visibility, setcatalog_visibility] = useState(false);
  const [featured, setfeatured] = useState(false);
  const [post_state_id, setpost_state_id] = useState("");
  const [birthday, setbirthday] = useState("");
  const [deathday, setdeathday] = useState("");
  const [poststatus, setpoststatus] = useState([]);

  //const today = new Date().toISOString().slice(0, 10);
// eslint-disable-next-line
  const [loading2, setloading2] = useState(false);
  // eslint-disable-next-line
  const [loading3, setLoading3] = useState(false);
  const [progress, setProgress] = useState(0);

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const tableActorShow = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/actorshow/${id}`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setname(row?.data?.name);
            setalsoknownas(row?.data?.alsoknownas);
            setplace_of_birth(row?.data?.place_of_birth);
            const status = +Number(row?.data?.post_state_id.toString());

            setpost_state_id(status);
            setphoto(row?.data?.photo);
            setperson_info(row?.data?.person_info)
            setimdb_id(row?.data?.imdb_id);
            settmdb_id(row?.data?.tmdb_id);
            // const today = new Date(row?.data?.post_date)
            //   .toISOString()
            //   .slice(0, 10);

            // setpostmedate(today);

            

            if (row?.data?.featured === null) {
              setfeatured(false);
            } else if (row?.data?.featured === 1) {
                setfeatured(true);
            } else {
                setfeatured(false);
            }

            if (row?.data?.catalog_visibility === null) {
              setcatalog_visibility(false);
            } else if (row?.data?.catalog_visibility === 1) {
                setcatalog_visibility(true);
            } else {
                setcatalog_visibility(false);
            }

            if (row?.data?.birthday === null || row?.data?.birthday === "") {
              setbirthday("");
            } else if (row?.data?.birthday != null) {
                setbirthday(row?.data?.birthday);
            } else {
                setbirthday("");
            }

            if (row?.data?.deathday === null || row?.data?.deathday === "") {
              setdeathday("");
            } else if (row?.data?.deathday !=null) {
                setdeathday(row?.data?.deathday);
            } else {
                setdeathday("");
            }
            setLoading(false);
          } else if (row?.success === false) {
            setLoading(false);
          }

        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const tableStatus = async () => {
    try {
      const userToken = localStorage.getItem("userToken");
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
      };
      let url = `${rootURL}/poststatus`;
      setLoading(true);
      fetch(url, config)
        .then((row) => row.json())
        .then((row) => {
          if (row?.success === true) {
            setpoststatus(row?.data);
            // successMessage(row?.message);
            setLoading(false);
          } else if (row?.success === false) {
            // failedMessage(row?.message);
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    tableStatus();
    tableActorShow();
// eslint-disable-next-line
  }, [id]);

  const handleStatus = (event) => {
    setpost_state_id(event.target.value);
  };

  const selectedFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      setLoading3(true);
      const s3 = new AWS.S3({
        accessKeyId: awsID,
        secretAccessKey: awsSECRET,
      });

      const params = {
        Bucket: awsBucketName,
        Key: create_UUID() + image.name, // File name you want to save as in S3
        Body: image,
        ContentType: image.type,
        ACL: "public-read",
      };

      s3.upload(params, function (err, data) {
        if (err) {
          console.log(err, "failure");
          setLoading3(false);
        }
        // console.log("success");

        // console.log(data.Location, "link");
        let photoUrl = data.Location;
        setphoto(photoUrl);
        setLoading3(false);

        // Editor.insertEmbed(cursorLocation, "image", photoUrl);
        // resetUploader();
      }).on("httpUploadProgress", function (progress) {
        let uploaded = Math.round((progress.loaded / progress.total) * 100);
        setProgress(uploaded);
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userToken = localStorage.getItem("userToken");
    setloading2(true);
    let mov = [];
    let series = [];
    const actorObj = {
      id,
      name,
      alsoknownas,
      place_of_birth,
      photo,
      person_info,
      imdb_id,
      tmdb_id,
      catalog_visibility,
      featured,
      post_state_id,
      birthday,
      deathday,
      mov,
      series,
    };

    fetch(`${rootURL}/updateactors`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${userToken}`,
        Accept: "application/form-data",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(actorObj),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          successMessage("Success");
          //   console.log("data", result)
          setTimeout(() => {
            history("/actor-manage/actor-list");
            //history(`/user-manage/user-profile/${result?.data?.id}`);
          }, 3000);
        } else if (result.success === false) {
          setloading2(false);
          failedMessage(result.message);
        }
      })
      .catch((error) => {
        setloading2(false);
        failedMessage("Internal Server Error");
      })
      .finally(() => {
        setloading2(false);
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress size={70} thickness={4} />
          </div>
        </>
      ) : (
        <>
          <Layout title="View Celebrity" content="container">
            <Block.Head>
              <Block.HeadBetween>
                <Block.HeadContent>
                  <Block.Title tag="h2">View Celebrity</Block.Title>
                  <nav>
                    <ol className="breadcrumb breadcrumb-arrow mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/actor-manage/actor-list">Celebrity</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        View Celebrity
                      </li>
                    </ol>
                  </nav>
                </Block.HeadContent>
                <Block.HeadContent>
                    <ul className="d-flex">
                        <li>
                            <Link to="/actor-manage/actor-list" className="btn btn-primary btn-md d-md-none">
                                {/* <Icon name="eye"/> */}
                                <span>Back</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/actor-manage/actor-list" className="btn btn-primary d-none d-md-inline-flex">
                                {/* <Icon name="eye"/> */}
                                <span>Back</span>
                            </Link>
                        </li>
                    </ul>
                </Block.HeadContent>
              </Block.HeadBetween>
            </Block.Head>

            <Block>
              <Form onSubmit={handleSubmit}>
                <Row className="g-gs">
                  <Col xxl="9">
                    <div className="gap gy-4">
                      <div className="gap-col">
                        <Card className="card-gutter-md">
                          <Card.Body>
                            <Row className="g-gs">
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Cover</Form.Label>
                                  <div className="form-control-wrap">
                                    <div className="image-upload-wrap d-flex flex-column align-items-center">
                                      <div>Upload progress {progress}%</div>
                                      <div className="media media-huge border">
                                        <img
                                          id="image-result"
                                          className="w-100 h-100"
                                          src={photo}
                                          alt="avatar"
                                        />
                                      </div>
                                      <div className="pt-3">
                                        <input
                                          className="upload-image"
                                          data-target="image-result"
                                          id="change-avatar"
                                          type="file"
                                          max="1"
                                          hidden
                                          onChange={selectedFile}
                                        />
                                        {/* <label
                                          htmlFor="change-avatar"
                                          className="btn btn-md btn-primary"
                                          disabled={loading3}
                                        >
                                          {loading3
                                            ? "Uploading ..."
                                            : "Upload"}
                                        </label> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-note mt-3">
                                    Set the article thumbnail image. Only *.png,
                                    *.jpg and *.jpeg image files are accepted.
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                  Stage Name *
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="name"
                                      placeholder="FillName"
                                      disabled
                                      value={name}
                                      required
                                      onChange={(e) => setname(e.target.value)}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Also Known As
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="alsoknownas"
                                      placeholder="Also Known As"
                                      disabled
                                      value={alsoknownas}
                                      onChange={(e) =>
                                        setalsoknownas(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="productname">
                                    Place Of Birth
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="place_of_birth"
                                      placeholder="Place of Birth"
                                      disabled
                                      value={place_of_birth}
                                      onChange={(e) =>
                                        setplace_of_birth(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="vat-amount">
                                    Date Of Birth
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="date"
                                      id="birthday"
                                      name="birthday"
                                      value={birthday}
                                      disabled
                                      onChange={(newValue) => {
                                        setbirthday(newValue.target.value);
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>
                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="vat-amount">
                                    Date Of Death
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="date"
                                      id="deathday"
                                      name="deathday"
                                      disabled
                                      value={deathday}
                                      onChange={(newValue) => {
                                        setdeathday(newValue.target.value);
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label>Description</Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      as="textarea"
                                      rows="3"
                                      value={person_info}
                                      disabled
                                      placeholder=""
                                      onChange={(e) =>
                                        setperson_info(e.target.value)
                                      }
                                    ></Form.Control>
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="imdb_id">
                                    IMDB ID
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="imdb_id"
                                      placeholder="IMDB ID"
                                      value={imdb_id}
                                      disabled
                                      onChange={(e) =>
                                        setimdb_id(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <Form.Group className="form-group">
                                  <Form.Label htmlFor="tmdb_id">
                                    TMDB ID
                                  </Form.Label>
                                  <div className="form-control-wrap">
                                    <Form.Control
                                      type="text"
                                      id="tmdb_id"
                                      placeholder="TMDB ID"
                                      value={tmdb_id}
                                      disabled
                                      onChange={(e) =>
                                        settmdb_id(e.target.value)
                                      }
                                    />
                                  </div>
                                </Form.Group>
                              </Col>

                              <Col lg="12">
                                <div className="form-control-wrap">
                                  <FormControl fullWidth size="small">
                                    <Form.Label htmlFor="status_id">
                                      Status *
                                    </Form.Label>
                                    <Select
                                      labelId="demo-select-small"
                                      id="demo-simple-select"
                                      value={post_state_id ?? ""}
                                      label="Status"
                                      disabled
                                      onChange={handleStatus}
                                      required
                                    >
                                      {poststatus.map((status) => (
                                        <MenuItem
                                          key={status.id}
                                          value={status.id}
                                        >
                                          {loading === false ? status.name : ""}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="CATALOG VISIBILITY"
                                  control={
                                    <Checkbox
                                      checked={catalog_visibility}
                                      onChange={(e) => {
                                        setcatalog_visibility(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>

                              <Col lg="6">
                                <FormControlLabel
                                  label="FEATURED"
                                  control={
                                    <Checkbox
                                      checked={featured}
                                      onChange={(e) => {
                                        setfeatured(e.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>

                      {/* <div className="gap-col">
                        <ul className="d-flex align-items-center gap g-3">
                          <li>
                            <Button type="submit" variant="contained">
                              {loading2 ? "Saving...." : "Save"}
                            </Button>
                          </li>
                          <li>
                            <Link
                              to="/actor-manage/actor-list"
                              className="btn border-0"
                            >
                              Cancel
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </Col>
                  {/* <Col xxl="3">
                    <Card className="card-gutter-md">
                      <Card.Body>
                        <Row className="g-gs">
                          <Col className="col-12">
                           
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col> */}
                </Row>
              </Form>
            </Block>
          </Layout>
        </>
      )}
    </>
  );
}

export default ViewActor;
